<template>
    <div>
        <div class="box bbb">
            <div v-if="load">
                <SummerNote :id="'summernote' + new Date().getTime()" v-model="info.content" />
            </div>
			<el-button  class="btn" type="primary" @click="save">{{$t('btnTip.submit')}}</el-button>
        </div>
    </div>
</template>
<script>
import SummerNote from '@/components/summernote';
import { getServiceIntroduce, editServiceIntroduce } from '@/api/index'
export default {
    name:'useEdit',
    data(){
        return {
            info:{
                id:'',
                content:''
            },
            load:false
        }
    },
    components:{SummerNote},
    created(){
        this.get_info()
    },
    methods:{
        async get_info(){
            const res = await getServiceIntroduce(1)
            this.info=res
            this.load=true
        },
        async save(){
            await editServiceIntroduce(this.info)
            this.$message.success({
                message:this.$t('changeSuccess')
            })
        }
    },
}
</script>
<style land="scss" scoped>
.bbb{
	position: relative;
	padding-bottom: 50px;
}
.bbb .btn{
	position: absolute;
	bottom:3px;
	right: 20px;
}
</style>
